import React, { useEffect } from "react"
import { GatsbySeo } from 'gatsby-plugin-next-seo'
import Layout from "../components/layout"
import './midia.scss'
import PreFooter from "../components/preFooter"
import trackEvent from "../analyticsHelpers"
import Techcrunch from '../images/logo-tech-crunch.png'
import Draft from '../images/logo-draft.png'
import Exame from '../images/logo-exame.png'
import Aspas from '../images/aspas.png'

import Folha from '../images/logo-folha-de-sp.png'
import Startupi from '../images/logo-startupi.png'
import Veja from '../images/logo-veja-saude.png'

import Crescer from '../images/logo-crescer.png'
import PEGN from '../images/logo-peq-empresas.png'
import Uol from '../images/logo-uol.png'
import { Divider } from "../components/atoms/lines/Divider"
import CNN from '../images/logo-cnn.png'
import Forbes from '../images/logo-forbes.png'
import FastCompany from '../images/logo-fast-company.png'
import Estadao from '../images/logo-estadao.png'
import Universa from '../images/logo-universa.png'

const Midia = () => {
  useEffect(() => {
    trackEvent("sitenovo-midia-visualizou-tela")
  }, [])
  return (
    <Layout>
      <GatsbySeo
        title='Theia | Mídia'
        description='.'
        language='pt-br'
        canonical='https://blog.theia.com.br/midia'
        openGraph={{
          type: 'website',
          url: 'https://blog.theia.com.br/midia',
          title: 'Theia | Mídia',
          description: '.',
          images: [
            {
              url: 'https://theia.com.br/theia.png',
              width: 250,
              height: 367,
              alt: 'Og Imagem Alt',
            },
          ],
        }}
        metaTags={[{
          name: 'dc:title',
          content: 'Theia | Mídia'
        }, {
          name: 'dc:description',
          content: '.'
        }, {
          name: 'dc:language',
          content: 'BR',
        }, {
          name: 'reply-to',
          content: 'suporte@theia.com.br',
        }, {
          name: 'author',
          content: 'Theia',
        }, {
          name: 'description',
          content: '.',
        }, {
          name: 'keywords',
          content: 'gravidez, sintomas de gravidez, testes de gravidez, ginecologistas, obstetras, Fisioterapeuta Pelvica, psicologos, fisioterapeuta pelvico, preparador fisico, pré-natal, pre natal, semanas de gestacao, ensaio gestante, exame de gravidez, mulher gravida, ginecologistas unimed,semanas gestacionais, gravidez ectopica, consulta pre natal, consulta pré natal, parto, períneo, parto normal, parto humanizado, puerpério, cesaria, cesariana, parto cesaria, dentista, atendimento odontológico na gestação, parto cesária',
        },
        , {
          property: 'twitter:card',
          content: 'summary_large_image',
        }
        ]}
      />
      <div className="main-container-midia">
        <section className="grid-wrapper-section midia section1 text-center items-center lg:items-start">
              
          <h2 className="item1 text-dsTitle2 text-primary text-center">
            Theia na mídia
          </h2>
          <p className="item2 mt-4 text-textSecondary text-dsTextMedium">Acompanhe o que os canais estão falando sobre a Theia</p>
          <div className="item-midia mt-12">
            <p className="bulllet-timeline bullet1 text-textMedium lg:text-dsTextLarge text-primary">2021</p>
            <div className="flex flex-nowrap lg:flex-wrap flex-col lg:flex-row container-midia">
              <div className="item2 flex flex-col text-left mt-6 lg:mt-0 this-year">
                <span className="media-icon"><img src={Estadao} alt="" className="mx-auto" /></span>
                  <p className="text-dsTextSmal text-textPrimary">
                    "Já a Theia mira um mercado grande e cheio de lacunas: saúde da mulher. A empresa, fundada por Paula Crespi e Flávia Deustch, tem uma rede de 38 especialistas para atendimentos em pré-natal, parto e pós-parto - além de ginecologistas, há profissionais como psicólogos, nutricionistas e pediatras."
                  </p>
                  <Divider
                    maxWidth="100%"
                    border="1px solid #EEEEEE"
                    className="mt-6 mb-2"
                  />
                  <p className="text-textSecondary text-dsTextSmal italic">Julho/2021 • Estadão</p>
                  <a href="https://www.estadao.com.br/infograficos/link,healthtechs-ganham-espaco-e-mostram-que-estao-saudaveis,1182071" target="_blank" className="text-textSmaller text-primary mt-3">Ler matéria</a>
              </div>
              <div className="flex flex-col text-left mt-8 lg:mt-0">
                <span className="media-icon media-icon-second"><img src={CNN} alt="" className="mx-auto" /></span>
                <p className="text-dsTextSmal text-textPrimary">
                  "As fundadoras Flávia Deutsch e Paula Crespi conseguiram captar recursos em 2019 para o desenvolvimento da marca, levantando o maior investimento daquele ano conquistado por mulheres na América Latina"
                </p>
                <Divider
                  maxWidth="100%"
                  border="1px solid #EEEEEE"
                  className="mt-6 mb-2"
                />
                <p className="text-textSecondary text-dsTextSmal italic">Abril/2021 • CNN</p>
                <a href="https://www.cnnbrasil.com.br/business/2021/04/22/menos-de-5-das-startups-sao-fundadas-por-mulheres-como-mudar-esse-cenario" target="_blank" className="text-textSmaller text-primary mt-3">Ler matéria</a>
              </div>
              <div className="flex flex-col text-left mt-8 lg:mt-0">
                <span className="media-icon"><img src={Veja} alt="" className="mx-auto" /></span>
                  <p className="text-dsTextSmal text-textPrimary">
                    "Cesáreas ainda dominam a cena dos partos: precisamos reverter isso"
                  </p>
                  <Divider
                    maxWidth="100%"
                    border="1px solid #EEEEEE"
                    className="mt-6 mb-2"
                  />
                  <p className="text-textSecondary text-dsTextSmal italic">Março/2021 • Veja Saúde</p>
                  <a href="https://saude.abril.com.br/blog/com-a-palavra/cesareas-ainda-dominam-a-cena-dos-partos-precisamos-reverter-isso/" target="_blank" className="text-textSmaller text-primary mt-3">Ler matéria</a>
              </div>
          
              <div className="flex flex-col text-left mt-8 lg:mt-0">
                <span className="media-icon"><img src={Universa} alt="" className="mx-auto" /></span>
                  <p className="text-dsTextSmal text-textPrimary">
                    "Elas criaram startup para conectar grávida a doula e a outros especialistas"
                  </p>
                  <Divider
                    maxWidth="100%"
                    border="1px solid #EEEEEE"
                    className="mt-6 mb-2"
                  />
                  <p className="text-textSecondary text-dsTextSmal italic">Março/2020 • Universa Uol</p>
                  <a href="https://www.uol.com.br/universa/noticias/redacao/2021/03/25/elas-criaram-startup-para-conectar-gestantes-a-equipe-humanizada-de-saude.htm" target="_blank" className="text-textSmaller text-primary mt-3">Ler matéria</a>
              </div>
              <div className="flex flex-col text-left mt-8 lg:mt-0 mb-4 lg:mb-0">
                <span className="media-icon"><img src={FastCompany} alt="" className="mx-auto" /></span>
                  <p className="text-dsTextSmal text-textPrimary">
                    "Ainda raras, mulheres founders de startups inspiram setor"
                  </p>
                  <Divider
                    maxWidth="100%"
                    border="1px solid #EEEEEE"
                    className="mt-6 mb-2"
                  />
                  <p className="text-textSecondary text-dsTextSmal italic">Fevereiro/2021 • Fast Company</p>
                <a href="https://fastcompanybrasil.com/tech/ainda-raras-mulheres-a-frente-de-startups-inspiram-setor/" target="_blank" className="text-textSmaller text-primary mt-3">Ler matéria</a>
              </div>
            </div>
          </div>
          <Divider
            maxWidth="100%"
            border="1px solid #EEEEEE"
            className="divider-item hidden lg:block"
          />
        </section>
        <section className="grid-wrapper-section midia text-center items-center lg:items-start">
          <div className="item-midia">
          <p className="bulllet-timeline bullet2 text-textMedium lg:text-dsTextLarge text-primary">2020</p>
            <div className="flex flex-nowrap lg:flex-wrap flex-col lg:flex-row container-midia section2">
              <div className="flex flex-col text-left mt-2 lg:mt-0">
                <span className="media-icon"><img src={Folha} alt="" className="mx-auto" /></span>
                  <p className="text-dsTextSmal text-textPrimary">
                    "Paula Crespi e Flavia Deustch Gotfryd, fundadoras da Theia, uma femtech-como são chamadas as empresas de tecnologia focadas na saude da mulher."
                  </p>
                  <Divider
                    maxWidth="100%"
                    border="1px solid #EEEEEE"
                    className="mt-6 mb-2"
                  />
                  <p className="text-textSecondary text-dsTextSmal italic">Novembro/2020 • Folha de São Paulo</p>
                  <a href="https://www1.folha.uol.com.br/mercado/2020/11/ecossistema-masculino-no-mundo-tec-opera-contra-negocios-liderados-por-mulheres.shtml" target="_blank" className="text-textSmaller text-primary mt-3">Ler matéria</a>
              </div>
              <div className="flex flex-col text-left mt-8 lg:mt-0">
                <span className="media-icon media-icon-second"><img src={Crescer} alt="" className="mx-auto" /></span>
                <p className="text-dsTextSmal text-textPrimary">
                  "A saúde dos bebês, a dor do parto...Pesquisa mostra quais são os maiores medos das mães."
                </p>
                <Divider
                  maxWidth="100%"
                  border="1px solid #EEEEEE"
                  className="mt-6 mb-2"
                />
                <p className="text-textSecondary text-dsTextSmal italic">Novembro/2020 • Crescer</p>
                <a href="https://revistacrescer.globo.com/Gravidez/noticia/2020/11/saude-dos-bebes-dor-do-parto-pesquisa-mostra-quais-sao-os-maiores-medos-das-maes.html" target="_blank" className="text-textSmaller text-primary mt-3">Ler matéria</a>
              </div>
              <div className="flex flex-col text-left mt-8 lg:mt-0">
                <span className="media-icon"><img src={Uol} alt="" className="mx-auto" /></span>
                  <p className="text-dsTextSmal text-textPrimary">
                    "51% das mulheres queriam parto normal, mas só 32% fizeram, aponta pesquisa."
                  </p>
                  <Divider
                    maxWidth="100%"
                    border="1px solid #EEEEEE"
                    className="mt-6 mb-2"
                  />
                  <p className="text-textSecondary text-dsTextSmal italic">Novembro/2020 • UOL</p>
                <a href="https://www.uol.com.br/vivabem/noticias/redacao/2020/11/21/51-das-mulheres-queriam-parto-normal-mas-so-32-fizeram-aponta-pesquisa.htm" target="_blank" className="text-textSmaller text-primary mt-3">Ler matéria</a>
              </div>
          
              <div className="flex flex-col text-left mt-8 lg:mt-0">
                <span className="media-icon"><img src={PEGN} alt="" className="mx-auto" /></span>
                  <p className="text-dsTextSmal text-textPrimary">
                  "Startup que une pais e mães a profissionais diversos agora quer focar em mulheres grávidas."
                  </p>
                  <Divider
                    maxWidth="100%"
                    border="1px solid #EEEEEE"
                    className="mt-6 mb-2"
                  />
                  <p className="text-textSecondary text-dsTextSmal italic">Novembro/2020 •PEGN</p>
                <a href="https://revistapegn.globo.com/Mulheres-empreendedoras/noticia/2020/11/startup-que-une-pais-e-maes-profissionais-diversos-agora-quer-focar-em-mulheres-gravidas.html" target="_blank" className="text-textSmaller text-primary mt-3">Ler matéria</a>
              </div>
              <div className="flex flex-col text-left mt-8 lg:mt-0">
                <span className="media-icon"><img src={Forbes} alt="" className="mx-auto" /></span>
                  <p className="text-dsTextSmal text-textPrimary">
                    "A Theia se destacou ao se tornar a companhia fundada apenas por mulheres a conseguir levantar o maior valor em investimentos de 2019."
                  </p>
                  <Divider
                    maxWidth="100%"
                    border="1px solid #EEEEEE"
                    className="mt-6 mb-2"
                  />
                  <p className="text-textSecondary text-dsTextSmal italic">Setembro/2020 • Forbes</p>
                <a href="https://forbes.com.br/forbes-collab/2020/09/camila-farani-liderancas-femininas-revolucionam-as-startups/" target="_blank" className="text-textSmaller text-primary mt-3">Ler matéria</a>
              </div>
              <div className="flex flex-col text-left mt-8 lg:mt-0">
                <span className="media-icon"><img src={Draft} alt="" className="mx-auto" /></span>
                  <p className="text-dsTextSmal text-textPrimary">
                  "A healthtech se posiciona como a primeira plataforma digital a oferecer a mães e pais o acesso a uma rede de profissionais especializados como pediatras, psicólogos, ginecologistas, nutricionistas, coaching de carreira, terapeuta do sono, aleitamento materno etc."
                  </p>
                  <Divider
                    maxWidth="100%"
                    border="1px solid #EEEEEE"
                    className="mt-6 mb-2"
                  />
                  <p className="text-textSecondary text-dsTextSmal italic">Agosto/2020 • Draft</p>
                <a href="https://www.projetodraft.com/conciliar-filhos-e-trabalho-esta-dificil-que-tal-uma-rede-de-apoio-24h/" target="_blank" className="text-textSmaller text-primary mt-3">Ler matéria</a>
              </div>

              <div className="flex flex-col text-left mt-8 lg:mt-0">
                <span className="media-icon"><img src={Veja} alt="" className="mx-auto" /></span>
                  <p className="text-dsTextSmal text-textPrimary">
                    "Dilemas e cuidados no retorno às aulas."
                  </p>
                  <Divider
                    maxWidth="100%"
                    border="1px solid #EEEEEE"
                    className="mt-6 mb-2"
                  />
                  <p className="text-textSecondary text-dsTextSmal italic">Agosto/2020 • Veja Saúde</p>
                <a href="https://saude.abril.com.br/blog/com-a-palavra/dilemas-e-cuidados-no-retorno-as-aulas/" target="_blank" className="text-textSmaller text-primary mt-3">Ler matéria</a>
              </div>
              <div className="flex flex-col text-left mt-8 lg:mt-0">
                <span className="media-icon"><img src={Estadao} alt="" className="mx-auto" /></span>
                  <p className="text-dsTextSmal text-textPrimary">
                    "Theia, uma plataforma digital que promove soluções em rede para pais e mais que trabalham, com profissionais dedicados às necessidades dos pais."
                  </p>
                  <Divider
                    maxWidth="100%"
                    border="1px solid #EEEEEE"
                    className="mt-6 mb-2"
                  />
                  <p className="text-textSecondary text-dsTextSmal italic">Julho/2020 • Estadão</p>
                <a href="https://www.estadao.com.br/infograficos/economia,o-medo-de-voltar-ao-local-de-trabalho-na-pandemia,1108582" target="_blank" className="text-textSmaller text-primary mt-3">Ler matéria</a>
              </div>
              <div className="flex flex-col text-left mt-8 lg:mt-0 mb-4 lg:mb-0">
                <span className="media-icon"><img src={Techcrunch} alt="" className="mx-auto" /></span>
                  <p className="text-dsTextSmal text-textPrimary">
                    "Theia, which ended the year as the largest all-female founded company raise in Latin America."
                  </p>
                  <Divider
                    maxWidth="100%"
                    border="1px solid #EEEEEE"
                    className="mt-6 mb-2"
                  />
                  <p className="text-textSecondary text-dsTextSmal italic">Julho/2020 • Tech Crunch</p>
                <a href="https://techcrunch.com/2020/02/06/latin-america-takes-the-global-lead-in-vc-directed-to-female-co-founders/" target="_blank" className="text-textSmaller text-primary mt-3">Ler matéria</a>
              </div>
            </div>
          </div>
          <Divider
            maxWidth="100%"
            border="1px solid #EEEEEE"
            className="divider-item hidden lg:block"
          />
        </section>
        <section className="grid-wrapper-section midia text-center items-center lg:items-start">
          <div className="item-midia">
          <p className="bulllet-timeline bullet3 text-textMedium lg:text-dsTextLarge text-primary">2019</p>
            <div className="flex flex-nowrap lg:flex-wrap flex-col lg:flex-row container-midia section3">
              <div className="flex flex-col text-left mt-2 lg:mt-0">
                <span className="media-icon"><img src={Startupi} alt="" className="mx-auto" /></span>
                  <p className="text-dsTextSmal text-textPrimary">
                    "O fundo Kaszek Ventures, um dos maiores da América Latina, anunciou o aporte de R$7 milhões na healthtech Theia."
                  </p>
                  <Divider
                    maxWidth="100%"
                    border="1px solid #EEEEEE"
                    className="mt-6 mb-2"
                  />
                  <p className="text-textSecondary text-dsTextSmal italic">Outubro/2019 • Startupi</p>
                  <a href="https://startupi.com.br/2019/10/startup-que-ajuda-pais-e-maes-que-trabalham-fora-nos-cuidados-com-os-filhos-recebe-aporte-de-r7-milhoes/" target="_blank" className="text-textSmaller text-primary mt-3">Ler matéria</a>
              </div>
              <div className="flex flex-col text-left mt-8 lg:mt-0">
                <span className="media-icon media-icon-second"><img src={Exame} alt="" className="mx-auto" /></span>
                <p className="text-dsTextSmal text-textPrimary">
                  "Flávia Deutsch e Paul a Crespi vivenciaram o mundo das startups e criaram um site que liga mães e pais aos conselhos de profissionais especializados."
                </p>
                <Divider
                  maxWidth="100%"
                  border="1px solid #EEEEEE"
                  className="mt-6 mb-2"
                />
                <p className="text-textSecondary text-dsTextSmal italic">Outubro/2019 • Exame</p>
                <a href="https://exame.com/pme/maes-empreendedoras-captam-r-7-mi-para-site-que-tira-duvidas-dos-pais/" target="_blank" className="text-textSmaller text-primary mt-3">Ler matéria</a>
              </div>
            </div>
          </div>
        </section>
      </div>
      <section className="w-full flex flex-col text-center bottom-media">
        <h2 className="font-medium text-primary text-titleMedium lg:text-dsTitle2">Quer contatar a assessoria de comunicação da Theia?</h2>
        <p className="mt-4 text-textSecondary text-dsTextSmal lg:text-dsTextMedium break-words">Mande um e-mail para <a href="mailto:adriana.veronez@magnitudecomunicacao.com.br">adriana.veronez@magnitudecomunicacao.com.br</a></p>
      </section>
    </Layout>
  )
}

export default Midia